import React, { useState, useEffect } from "react";
import "./nullcontent.css";
import CloseIcon from "@mui/icons-material/Close";
import Modal from 'react-bootstrap/Modal';
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { updateoutfit } from "../api/auth";
import { deleteoutfit } from "../api/auth";

const NullContent = (props) => {
  const { setCalanderdata, getOutfitData, storedImages } = props;
  const [outfitName, setOutfitName] = useState("");
  const [outfitDesc, setOutfitDesc] = useState("");
  const [selectedOutfitId, setSelectedOutfitId] = useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleEditOutfit = (outfit) => {
    setOutfitName(outfit.name || "");
    setOutfitDesc(outfit.desc || "");
    setSelectedOutfitId(outfit.id);
    handleShow();
  };

  const hasImages = storedImages.some(
    (outfit) => outfit.images && outfit.images.length > 0,
  );

  async function handleDeleteOf(of_Id) {
    setLoading(true);
    try {
      const response = await deleteoutfit(of_Id);
      if (response) {
        await getOutfitData();
        console.log("Item deleted successfully:", response);
      }
    } catch (error) {
      console.error("Error deleting item:", error.message);
    } finally {
      setLoading(false);
    }
  }

  const handleSaveOutfit = async () => {
    setLoading(true);
    console.log("Selected Outfit ID:", selectedOutfitId);
    const updateData = {
      name: outfitName,
      desc: outfitDesc,
    };

    try {
      const response = await updateoutfit(selectedOutfitId, updateData);
      if (response) {
        await getOutfitData();
        handleClose();
      }
    } catch (error) {
      console.error("Error updating outfit:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const url = "https://live.avertisystems.com/";

  console.log("storedImages", storedImages);

  useEffect(() => {
    setCalanderdata(storedImages);
  }, [storedImages, setCalanderdata]);

  return (
    <>
      {loading && (
        <div className="full-screen-loader">
          <div className="loaderji"></div>
        </div>
      )}
      {hasImages && (
        <div>
          <div className="row" style={{ justifyContent: "start", margin: "0" }}>
            {storedImages.map((outfit, setIndex) => (
              <div key={setIndex} className=" col-lg-3 col-4 columnthree">
                <span onClick={() => handleDeleteOf(outfit.id)} className="close-icd"><CloseIcon/></span>
                <div className='imgodvs'>
                  {outfit.images.map((imageObj, index) => (
                    <img
                      style={{
                        transition: 'opacity 0.3s ease-in-out',
                      }}
                      loading="lazy"
                      className="nullimageclass"
                      key={index} 
                      src={`${url}${imageObj.image}`}
                      alt={`outfit-${outfit.id}-image-${index}`} 
                    />
                  ))}
                </div>
                <div onClick={() => handleEditOutfit(outfit)} className='outfitname'><p className='pppp'>{outfit.name || "Unnamed Outfit"}</p></div>
                <div>
                  <Modal centered id="outfitedit" className='fade-modal' show={show} onHide={handleClose} style={{ overflow: "hidden" }}>
                    <div style={{display:"flex", justifyContent:"space-between", marginTop:"7px"}} >
                      <div>.</div>
                      <Modal.Title>OUTFIT NOTES</Modal.Title>
                      <div onClick={handleClose}><CloseIcon/></div>
                    </div>
                    <div className="gyuyg"></div>
                    <div className="ugui" style={{overflowY:"hidden"}}>
                      <div>
                        <input
                          className="inptyuoutfit"
                          placeholder="Enter Outfit name"
                          value={outfitName}
                          onChange={(e) => setOutfitName(e.target.value)}
                        />
                      </div>
                      <div style={{marginTop:"17px"}}>
                        <textarea 
                          className="txtyuoutfit"
                          placeholder="Enter Outfit notes"
                          value={outfitDesc}
                          onChange={(e) => setOutfitDesc(e.target.value)}
                        />
                      </div>
                      <div className="line121out"></div>
                      <div>
                        <div className="footer_bnt_coll" onClick={handleSaveOutfit}>
                          <div className="button_name_f">Save</div>
                          <ArrowRightAltIcon />
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {!hasImages && (
        <div style={{display:"flex", justifyContent:"center", alignItems:"center",width:"100%",minHeight:"68vh"}}>
          <h3 className="padh3">No Outfit Created</h3>
        </div>
      )}
    </>
  );
};

export default NullContent;