import React, { useState } from "react";
import "./boxie.css";
import SmoothImage from "./SmoothImage";
import Newpage from "./Newpage";
import Modal from "react-bootstrap/Modal";

import TabUnModal from "./TabUnModal";

import Dynamicpage from "./Dynamicpage";

import { deleteboxie } from "../api/auth";

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

export default function Boxie(props) {
	const { itemname, formData, setFormData, clientId, allitem, getBoxie } =
		props;
	const [show, setShow] = React.useState(false);
	const [selectProduct, setSelectProduct] = React.useState(null);
	const [selectdynamicProduct, setSelectDynamicProduct] = React.useState(null);
	const [openModal, setOpenModal] = React.useState(false);
	const [showFloatingElements, setShowFloatingElements] = useState(false);
	const [loadingji, setLoadingji] = useState(false);

	console.log("selectProduct", selectProduct);
	console.log("selectdynamicProduct", selectdynamicProduct);
	console.log("show", show);

	//fifth modal inside span
	const [dlt, setDlt] = useState(false);
	const handleCuted = () => setDlt(false);

	async function handleDelete(id) {
		setLoadingji(true);
		try {
			const response = await deleteboxie(id);
			handleclose();
			if (response) {
				setFormData((prevData) => prevData.filter((item) => item.id !== id));
				getBoxie();
				console.log("Item deleted successfully:", response);
			}
		} catch (error) {
			console.error("Error deleting item:", error.message);
		} finally {
			setLoadingji(false);
			handleclose();
		}
	}

	const confirmDelete = () => {
		if (selectProduct && selectProduct.id) {
			handleDelete(selectProduct.id); // Pass the correct id for deletion
			// handleclose();
		} else {
			console.error("No item selected for deletion");
		}
		document.getElementById("confirmationnn-box").style.display = "none";
	};

	const handleDeleteConfirmation = () => {
		document.getElementById("confirmationnn-box").style.display = "block";
	};

	const cancelDelete = () => {
		// Code to execute if user clicks No
		// console.log("User clicked No");
		document.getElementById("confirmationnn-box").style.display = "none";
	};

	//fourth modal inside span
	const [hanger, setHanger] = useState(false);
	const handleCuting = () => setHanger(false);
	const handleseing = () => setHanger(true);

	//third modal inside span
	const [dollar, setDollar] = useState(false);
	const handleCut = () => setDollar(false);
	const handlesee = () => setDollar(true);

	//second modal inside span
	const [graph, setGraph] = useState(false);
	const handleCloseing = () => setGraph(false);
	const handleShowing = () => setGraph(true);

	// model inside pic span
	const [showing, setShowing] = useState(false);
	const handletoClose = () => setShowing(false);
	const handletoShow = () => setShowing(true);
	//

	const [hide, setHide] = useState(false);
	const handleClose = () => setHide(false);
	const handleShow = () => setHide(true);

	const handleOpenmodal = () => {
		setOpenModal(true);
	};
	const handleClosemodal = () => {
		setOpenModal(false);
	};

	const handleopen = (value1, value2) => {
		setShow(true);
		setSelectProduct(value1);
		setSelectDynamicProduct(value2);
	};
	const handleclose = () => {
		setShow(false);
		// setSelectProduct(null);
		// setSelectDynamicProduct(null);
	};
	const toggleFloatingElements = () => {
		setShowFloatingElements(!showFloatingElements);
	};

	console.log("FormData", formData);

	const url = "https://live.avertisystems.com/";

	return (
		<>
			{loadingji && (
				<div className="full-screen-loader">
					<div className="loader"></div>
				</div>
			)}
			<div className="maindivie">
				<div className="maindivie" style={{minHeight:"80vh"}}>
					{Array.isArray(allitem) && allitem.length > 0 ? (
						allitem.map((item) => {
							return (
								<div className="col-md-3 col-6 takei chdiv" key={item.id}>
									<div className="imgdiv">
										<SmoothImage
											src={`${url}${item.img}`}
											alt={item.sub_category}
											onClick={() => handleopen(item, "data2")}
										/>
										<div className="middle">
											<div className="buttonnn">
												<div className="texttt">
													<i
														style={{ marginBottom: "4px", fontSize: "15px" }}
														className="bi bi-eye-slash-fill"
													></i>
													<br /> PRIVATE
												</div>
											</div>
											<div className="buttonnn">
												<div className="texttt">
													<i
														style={{ marginBottom: "4px", fontSize: "15px" }}
														className="bi bi-star"
													></i>
													<br /> FAVORITE
												</div>
											</div>
											<div className="buttonnn" onClick={handleShow}>
												<div className="texttt">
													<div className="lull">
														<div className="lul">!</div>
													</div>
													<br /> DO NOT STYLE
												</div>
											</div>
											<div className="buttonnn" onClick={handleShow}>
												<div className="texttt">
													<i
														style={{ marginBottom: "4px", fontSize: "15px" }}
														className="bi bi-archive"
													></i>
													<br /> ARCHIVE
												</div>
											</div>
										</div>
									</div>
									<div className="njnj">
										<p className="pp">{item.sub_category}</p>
									</div>
								</div>
							   );
							})
						  ) : (
							<div style={{display:"flex", justifyContent:"center", alignItems:"center",width:"100%"}}>
							<h3>No item added</h3>
							</div>
						  )}
				</div>
				<div className="lioplkjhgf"></div>
				<Modal
					centered
					show={hide}
					onHide={handleClose}
					backdrop="static"
					keyboard={false}
					style={{ overflow: "hidden" }}
					id="idy"
				>
					<Modal.Body className="bodystyle">
						<div className="item1">
							Are you sure you want to mark this item as
						</div>
						<div className="item2">"DO NOT STYLE"?</div>
						<div className="item1">
							Your stylist will no loner see this item in your
						</div>
						<div className="item2">wardobe.</div>
						<div className="margi"></div>
						<div>
							<div className="footer_bnt_ff">
								<div className="button_name_f">DO NOT STYLE</div>
								<i style={{ color: "black" }} class="bi bi-arrow-right"></i>
							</div>
						</div>

						<div className="marg"></div>

						<div onClick={handleClose}>
							<div className="footer_bntt_f">
								<div className="button_name_foot">CANCEL</div>
								<i style={{ color: "black" }} class="bi bi-arrow-right"></i>
							</div>
						</div>
					</Modal.Body>
				</Modal>

				<Modal
					id="vbnm"
					style={{ overflow: "hidden" }}
					centered
					className="footerid"
					show={show}
					onHide={handleclose}
				>
					{selectdynamicProduct === "data1" && (
						<Newpage selectProduct={selectProduct} />
					)}
					{selectdynamicProduct === "data2" && (
						<Dynamicpage selectProduct={selectProduct} />
					)}
					<Modal.Footer style={{ height: "0px", padding: "0px" }}>
						<div class="floating-container">
							<div class="floating-button" onClick={toggleFloatingElements}>
								{/* Change the plus icon to toggle the floating elements */}
								{showFloatingElements ? (
									<i style={{ fontSize: "22px" }} class="bi bi-x"></i>
								) : (
									<i
										style={{ fontSize: "22px" }}
										class="bi bi-three-dots-vertical"
									></i>
								)}
							</div>
							{showFloatingElements && ( // Conditionally render floating elements
								<div class="element-container">
									<span
										onClick={handletoShow}
										class="float-element tooltip-left"
									>
										<i class="material-ic">
											<i
												style={{ fontSize: "22px", color: "black" }}
												class="bi bi-pencil-fill"
											></i>
										</i>
									</span>

									<span
										onClick={handleShowing}
										class="float-element tooltip-left"
									>
										<i class="material-ic">
											<i style={{ color: "black" }} class="bi bi-graph-up"></i>
										</i>
									</span>

									<span onClick={handlesee} class="float-element tooltip-left">
										<i class="material-ic">
											<i
												style={{ color: "black" }}
												class="bi bi-currency-dollar"
											></i>
										</i>
									</span>

									<span onClick={handleseing} class="float-element">
										<i class="material-ic">
											<i style={{ fontSize: "22px" }} class="bi bi-option"></i>
										</i>
									</span>

									<span
										onClick={handleDeleteConfirmation}
										class="float-element"
									>
										<i class="material-ic">
											<i
												style={{ fontSize: "22px" }}
												class="bi bi-trash3-fill"
											></i>
										</i>
									</span>
									<div id="confirmationnn-box">
										<div className="diviie234">
											Are you sure you want to delete this item?
										</div>
										<div className="dltop">
											<div className="footer_dltop" onClick={confirmDelete}>
												<div>DELETE</div>
												<i
													style={{ color: "black" }}
													class="bi bi-arrow-right"
												></i>
											</div>
										</div>
										<div className="dltop1">
											<div onClick={cancelDelete} className="footer_dltop1">
												<div>CANCEL</div>
												<i
													style={{ color: "black" }}
													class="bi bi-arrow-right"
												></i>
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
						<Modal id="tabunmod" show={showing} onHide={handletoClose}>
							<Modal.Header closeButton>
								<Modal.Title>EDIT ITEM</Modal.Title>
							</Modal.Header>
							<div>
								{" "}
								<TabUnModal
									handleclose={handleclose}
									getBoxie={getBoxie}
									setFormData={setFormData}
									formData={formData}
									itemname={itemname}
									selectProduct={selectProduct}
									setSelectProduct={setSelectProduct}
									handletoClose={handletoClose}
								/>{" "}
							</div>
						</Modal>
						{/* diff model ^i model inside pic span*/}

						<Modal id="qwertyuo" centered show={graph} onHide={handleCloseing}>
							<Modal.Header>
								<div className="cname1">
									<div className="cname11">
										<div className="cname13">-1</div>
									</div>
									<div className="cname12">Worn 0 times in 0 days</div>
									<div className="cname11">
										<div className="cname13">+1</div>
									</div>
								</div>
							</Modal.Header>
							<Modal.Body style={{ overflowY: "hidden" }}>
								<div className="cname2">
									<h2 className="loliilo">PURCHASE DATE</h2>
									<input className="inptyu" placeholder="Purchased Date" />
								</div>
								<div className="cname2">
									<h2 className="loliilo">PURCHASE PRICE</h2>
									<input className="inptyu" />
								</div>
								<div className="cname2">
									<h2 className="loliilo">ORIGIAL PRICE</h2>
									<input className="inptyu" />
								</div>
								<div className="cname2">
									<h2 className="loliilo">SECONDHAND</h2>
									<input className="inptyu" />
								</div>
								<div className="cname2">
									<h2 className="loliilo">PURCHASE DATE</h2>
									<input
										className="inptyu"
										placeholder="Enter purchase location"
									/>
								</div>
								<div className="cname2">
									<h2 className="loliilo">TIMES WORN</h2>
									<input className="inptyu" />
								</div>
								<div className="cname2">
									<h2 className="loliilo">RESALE VALUE</h2>
									<input className="inptyu" />
								</div>
							</Modal.Body>
						</Modal>
						{/* //second modal inside span */}

						<Modal centered id="mnbvc" show={dollar} onHide={handleCut}>
							<Modal.Body style={{ overflowY: "hidden" }}>
								<div className="tipopo">COMING SOON!</div>
								<div className="tipopo1">We arerevamping our marketplace.</div>
								<div className="tipopo2">
									Come back soon to list an item for sale
								</div>
								<div className="tickiop">
									<div onClick={handleCut} className="footer_flioop">
										<div>OK</div>
										<i style={{ color: "black" }} class="bi bi-arrow-right"></i>
									</div>
								</div>
							</Modal.Body>
						</Modal>
						{/* //third modal inside span */}

						<Modal centered id="lkjh" show={hanger} onHide={handleCuting}>
							<Modal.Header
								style={{ display: "flex", justifyContent: "space-between" }}
							>
								<div onClick={handleCuting} handleCuting>
									<i style={{ color: "black" }} class="bi bi-arrow-left"></i>
								</div>
								<div>OUTFITS BY ITEM</div>
								<div></div>
							</Modal.Header>

							<Modal.Body style={{ overflowY: "hidden" }}>
								<div className="zxcvfds">
									You don't have any outfit for the item just yet
								</div>
							</Modal.Body>
						</Modal>
						{/* //fourth modal inside span */}

						<Modal centered id="lkiopl" show={dlt} onHide={handleCuted}>
							<Modal.Body style={{ overflowY: "hidden" }}>
								<div className="diviie234">
									Are you sure you want to delete this item?
								</div>
								<div className="dltop">
									<div
										className="footer_dltop"
										onClick={() => handleDelete(selectProduct)}
									>
										<div>DELETE</div>
										<i style={{ color: "black" }} class="bi bi-arrow-right"></i>
									</div>
								</div>
								<div className="dltop1">
									<div onClick={handleCuted} className="footer_dltop1">
										<div>CANCEL</div>
										<i style={{ color: "black" }} class="bi bi-arrow-right"></i>
									</div>
								</div>
							</Modal.Body>
						</Modal>
						{/* //fifth modal inside span */}
					</Modal.Footer>
				</Modal>

				{/* filer Modale */}

				<Modal id="qwertyu" show={openModal} centered onHide={handleClosemodal}>
					{/* <Filter_pro handleClosemodal={handleClosemodal} /> */}
				</Modal>
			</div>
		</>
	);
}
