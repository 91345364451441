import React from 'react';
import './ChildHomeMolecule.css';

const Noteic = () => {
  return (
    <div className='container'>
    <div style={{ 
      // background: "#fcfaed",
       marginBottom: "48px" }}>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div className="eat1">
          <p className="sty">Style</p>
          <p className="qui">QUIZ</p>
          <p className="put">Put language around</p>
          <p className="your">your style.</p>
          <p className="disc">Discover your style</p>
          <p className="dixc">archetypes and take the first</p>
          <p className="dixc">step towards defining your</p>
          <p className="dixc">three words</p>
        </div>
        <div className="eat2">
          <p className="sty">Style</p>
          <p className="qui">WORKSHOP</p>
          <p className="put">Never an instant makeover.</p>
          <p className="discx">Let us guide you through</p>
          <p className="dixc">an 8-week journey to</p>
          <p className="dixc">define your true personal</p>
          <p className="dixc">style</p>
        </div>
      </div>
      
      <div className="secd">
        <div className="sectext">Thoughtful entries on <span className="spn">Style, Sustainability and Industry;</span> we</div>
        <div className="sectext">care about this stuff and think you should too.</div>
      </div>

      <div className="eat3"></div>

      <div style={{ marginLeft: "24px", marginRight: "24px" }}>
        <div className="thirdt">We Shop for items, But Live Life in Outfits</div>
        <div className="fordt">It's seemingly unbreakable fashion maxim that the piece you love the most is the one that you're *about* to add to your closet. It doesn't even need to be sparkly, impractical confection for this to be true - in all likelihood, the piece you're currently coveting is something you think is actually quite practical. It's seemingly unbreakable fashion maxim that the piece you love the most is the one that you're *about* to add to your closet. It doesn't even need to be sparkly, impractical confection for this to be true - in all likelihood, the piece you're currently coveting is something you think is actually quite practical.</div>
        <div className="fordt">You bring it home, and inevitably it loses its shine almost as soon as the tags come off. The disconnect you're feeling? It stems from the simple fact that we all shop for items, but live in outfits. You bring it home, and inevitably it loses its shine almost as soon as the tags come off. The disconnect you're feeling? It stems from the simple fact that we all shop for items, but live in outfits.</div>
        <div className="fivdt">6 MIN READ</div>
      </div>

      <div className="eat4"></div>

      <div style={{ marginLeft: "24px", marginRight: "24px" }}>
        <div className="thirdt">The 3 Word Method: Take the Words Out of Your Mouth And Into Your Closet</div>
        <div className="fordt">It is possible to try new things and still have clarity on your own inner style. Thankfully, New York-based stylist and wardrobe consultant Allison Bornstein has answered this question with the widely talked-about Three Word Method. It is possible to try new things and still have clarity on your own inner style. Thankfully, New York-based stylist and wardrobe consultant Allison Bornstein has answered this question with the widely talked-about Three Word Method.</div>
        <div className="fivdt">4 MIN READ</div>
      </div>

      <div className="eat5"></div>

      <div style={{ marginLeft: "24px", marginRight: "24px" }}>
        <div className="thirdt">Gen Z Loves Fashion. So... How Exactly Is That Sustainable?</div>
        <div className="fordt">If there is one thing that gets under my skin, it's the sneaking suspicion that we may be hypocrites. With all the hope put on Gen Z to save the planet, it's time we get to the bottom of why we continue to voraciously consume fast fashion in a very, *very* unsustainable way. If there is one thing that gets under my skin, it's the sneaking suspicion that we may be hypocrites. With all the hope put on Gen Z to save the planet, it's time we get to the bottom of why we continue to voraciously consume fast fashion in a very, *very* unsustainable way.</div>
        <div className="fordt">Why is the generation that considers nothing off-limits when protesting for climate action also comfortable showing up to that same protest in Shein? How can the most environmentally conscious generation also be the biggest consumers of fast fashion? Why is the generation that considers nothing off-limits when protesting for climate action also comfortable showing up to that same protest in Shein? How can the most environmentally conscious generation also be the biggest consumers of fast fashion?</div>
        <div className="fivdt">5 MIN READ</div>
      </div>
    </div>
    </div>
  );
}

export default Noteic;
