import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Homies from "./Homies";
import { connect } from "react-redux";
import Section1 from "./layouts/Section1";
import { GetProfile, InitiatePayment, getChats, fetchEvents } from "../actions";

function OutFits(props) {
	const profile = localStorage.getItem("userDetail");
	if (profile) {
		// Parse the JSON string into an object
		const parsedProfile = JSON.parse(profile);

		// Fetch the 'id' from the profile object
		const userId = parsedProfile.id;

		console.log("User ID:", userId);
	} else {
		console.log("No user details found in localStorage.");
	}
	const parsedProfile = JSON.parse(profile);

		// Fetch the 'id' from the profile object
		const userId = parsedProfile.id;

		console.log("User IDdd:", userId);

	const userDetail = props;
	console.log(userDetail, "userDtrftthgttt=====");
	return (
		<div>
			<style>
				{`
          .tab-content {
            display: inline;
          }
          .nav-tabs .nav-link{
            width: 100%;
          }
          button.active{
           background: none !important;
          }
        
          .nav-item{
            display:flex;
            width: 33%;
            justify-content: center;
          }
        `}
			</style>
			<Section1 />
			<Tabs
				defaultActiveKey="home"
				id="uncontrolled-tab-example"
				className="mb-3"
				style={{ justifyContent: "space-between" }}
			>
				<Tab eventKey="home" title="EQUIP">
					<Homies clientId = {userId} />
				</Tab>
			</Tabs>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		userDetail: state.user.userDetail,
	};
};

export default connect(mapStateToProps, {
	InitiatePayment,
	GetProfile,
	getChats,
	fetchEvents,
})(OutFits);
