import React from 'react';

const Item4 = () => {
  return (
    <div className='container'>
    <div style={{ 
      // background: "#fcfaed", 
      marginBottom: "48px" }}>
      <div style={{ textAlign: "center" }}>
        <div className='thirdt'>
          <div className='sectext'>
            Unlock the <span className='spn'>full value</span> of an indyx digital wardrobe
          </div>
        </div>
        <div className='fordttt'>
          Our services are designed to help you get the most out of the closet you have while curating the closet of your dreams. Our services are designed to help you get the most out of the closet you have while curating the closet of your dreams.
        </div>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div
            className='eat11'
            style={{
              backgroundImage: "url('https://img.freepik.com/free-photo/young-business-woman-trendy-coat-outside_1303-21538.jpg?t=st=1716366091~exp=1716369691~hmac=de5bcca2cd4b8a6d83c5ab322d30d0d8c5f20016ca810f999d5dcf63ad9cf7f4&w=996')",
            }}
          >
            <p className='styu'>THE</p>
            <p className='quiop'>FEED</p>
            <p className='putop'>$15</p>
            <p className='yourop'>monthly</p>
            <p className='putopp'>Ongoing support of a stylist</p>
            <p className='youropu'>in your pocket</p>
          </div>
          <div
            className='eat22'
            style={{
              backgroundImage: "url('https://img.freepik.com/free-photo/middle-eastern-entrepreneur-wear-black-coat-blue-shirt-eyeglasses-against-office-building-smoking-cigarette_627829-5301.jpg?t=st=1716366514~exp=1716370114~hmac=5bebf78b2cc4401b6b4016b59c6481f0bbc32d8093c074f4c50768c9e832b5e4&w=996')",
            }}
          >
            <p className='styu'>THE</p>
            <p className='quiop'>LOOKBOOK</p>
            <p className='putopi'>$110+</p>
            <p className='putopp'>A fresh perspective from a</p>
            <p className='youropu'>stylist of your choice</p>
          </div>
        </div>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div
            className='eat12'
            style={{
              backgroundImage: "url('https://img.freepik.com/free-photo/girl-with-dreadlocks-walking-night-street-city_627829-9574.jpg?t=st=1716366412~exp=1716370012~hmac=dc5cad4c569bbfbabf1ef31c45f5689254c2a758c7626e8578bb1c3b808eb478&w=996')",
            }}
          >
            <p className='styu'>THE</p>
            <p className='quiop'>CATALOG</p>
            <p className='putopi'>$295</p>
            <p className='putopp'>An instant start to your digital</p>
            <p className='youropu'>wardrobe</p>
          </div>
          <div
            className='eat23'
            style={{
              backgroundImage: "url('https://img.freepik.com/free-photo/elderly-woman-drinking-coffee-outside-street_1303-19505.jpg?t=st=1716366234~exp=1716369834~hmac=dd512075c12558b6b8923fc229bdc376bf5bee76176ba9bf1f73cd6648c15916&w=996')",
            }}
          >
            <p className='styu'>THE</p>
            <p className='quiop'>EDIT</p>
            <p className='putopi'>$495</p>
            <p className='putoppiop'>A total wardrobe transformation</p>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Item4;
