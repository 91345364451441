import React, { useState } from "react";
import "./outfit.css";
import { Modal } from "react-bootstrap";
import SelectItem from "./SelectItem";
import Draggable from "react-draggable";
import { OutfitCrt } from "../api/auth";
import imageCompression from "browser-image-compression";
import Snackbar from '@mui/material/Snackbar';


function CreateOutfit(props) {
	const {
		handleclose_1,
		formData,
		setDragImageBox,
		allitem,
		clientId,
		getOutfitData,
	} = props;
	const [show, setShow] = useState(false);
	const [dragopen, setDragopen] = useState(false);
	const [dragdata, setDragData] = useState([]);
	const [selectedImageIndex, setSelectedImageIndex] = useState(null);

	const [errorMessage, setErrorMessage] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false); 

	const handleOpen = () => {
		setShow(true);
	};

	const handledragOpen = () => {
		setDragopen(true);
	};

	const handleClose = () => {
		setShow(false);
	};

	const url = "https://live.avertisystems.com/";

	const handleDragClose = (index) => {
		const updatedData = dragdata.filter((_, i) => i !== index);
		setDragData(updatedData);
		console.log("dragdata", dragdata);
	};

	const handleImageClick = (index) => {
		setSelectedImageIndex(index);
	};
	const handleMouseOut = () => {
		setSelectedImageIndex(null);
	};

	// const convertToBase64 = async (url) => {
	//     const response = await fetch(url);
	//     const blob = await response.blob();
	//     return new Promise((resolve, reject) => {
	//         const reader = new FileReader();
	//         reader.onloadend = () => resolve(reader.result); // This will give you the base64 data
	//         reader.onerror = reject;
	//         reader.readAsDataURL(blob); // Convert blob to base64
	//     });
	// };

	const convertToBase64 = async (url) => {
		const response = await fetch(url);
		const blob = await response.blob();

		// Compress the image before converting to base64
		const compressedBlob = await imageCompression(blob, {
			maxSizeMB: 1, // Maximum file size in MB (adjust as needed)
			maxWidthOrHeight: 800, // Set maximum width/height (adjust based on your requirement)
			useWebWorker: true, // Use Web Worker for faster compression
		});

		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result); // Get the base64 result
			reader.onerror = reject;
			reader.readAsDataURL(compressedBlob); // Convert compressed blob to base64
		});
	};

	const handlesetdropdowndata = async () => {
		if (dragdata.length === 0) {
            setErrorMessage("Please add items first to create an outfit.");
            setOpenSnackbar(true);
            return; // Stop the function execution if no images are added
        }
		try {
			const base64Images = await Promise.all(
				dragdata.map((image) => convertToBase64(`${url}${image}`)),
			);
			const OutData = {
				client_id: clientId,
				images: base64Images,
				folder_id: "",
				subfolder_id: "",
			};

			const response = await OutfitCrt(OutData);
			console.log("Outfit created successfully:", response);
			await getOutfitData();
			setDragImageBox(base64Images);
			handleclose_1();
		} catch (error) {
			console.error("Error creating outfit:", error.message);
		}
	};

	const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

	const handleClearImages = () => {
		setDragData([]);
	};

	return (
		<div className="out_fit">
			<div className="header_outfit">
				<i
					style={{ color: "black", cursor:"pointer" }}
					class="bi bi-arrow-left"
					onClick={handleclose_1}
				></i>
				<div className="name_ou">Create Outfit</div>
				<i style={{ color: "black" }} class="bi bi-tag-fill"></i>
			</div>

			<div className="body_outfit">
				<h3
					style={{ cursor: "pointer", fontSize: "12px" }}
					onClick={handleClearImages}
				>
					Clear
				</h3>

				<div className="collection_space">
					{dragopen
						? dragdata.map((data, index) => (
								<Draggable bounds="parent" key={index}>
									<div
										className="col-4"
										style={{ position: "relative" }}
										onMouseOver={() => handleImageClick(index)}
										onMouseOut={handleMouseOut}
									>
										<img src={`${url}${data}`} alt={`item-${index}`} />
										{selectedImageIndex === index && (
											<div style={{ position: "absolute", top: 0, right: 0 }}>
												<i
													class="bi bi-arrow-right"
													onClick={() => handleDragClose(index)}
												></i>
											</div>
										)}
									</div>
								</Draggable>
							))
						: null}
				</div>

				<div className="line_"></div>
				<div className="button_create" onClick={handleOpen}>
					<i style={{ color: "white" }} class="bi bi-plus-lg"></i>
				</div>
			</div>
			<div className="footer_outfit">
				<div className="footer_bnt_f" onClick={handlesetdropdowndata}>
					<div className="button_name_f">Save</div>
					<i style={{ color: "#fff" }} class="bi bi-arrow-right"></i>
				</div>
			</div>
			{errorMessage && (
                
                <Snackbar
                open={openSnackbar}
                autoHideDuration={2500}
                onClose={handleSnackbarClose}
                message={errorMessage}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            />
        )}
			<Modal id="lmkl" show={show} onHide={handleClose} centered>
				<SelectItem
					hadleClose={handleClose}
					formData={formData}
					setDragData={setDragData}
					handledragOpen={handledragOpen}
					allitem={allitem}
				/>
			</Modal>
		</div>
	);
}

export default CreateOutfit;
