import React, { useState, useEffect } from "react";
import "./homes.css";
import PropTypes from "prop-types";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Modal from "react-bootstrap/Modal";
import { Button, ButtonGroup, Container, Row, Column } from "react-bootstrap";
import ChildHomeMolecule from "./ChildHomeMolecule";
import Noteic from "./Noteic";
import Item4 from "./Item4";
import Itemm5 from "./Itemm5";
import ChildHome1 from "./ChildHome1";

import CustomPagination from "./CustomPagination";

import CreateOutfit from "./CreateOutfit";
import Category from "./Category";

import Trialcam from "./Trialcam";

import { indexy } from "../api/auth";
import { boxie } from "../api/auth";
import { subfolder } from "../api/auth";
import { getoutfit } from "../api/auth";

import Spinner from "react-bootstrap/Spinner";
function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`horizontal-tabpanel-${index}`}
			aria-labelledby={`horizontal-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Row sx={{ p: 3 }}>
					<div>{children}</div>
				</Row>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `horizontal-tab-${index}`,
		"aria-controls": `horizontal-tabpanel-${index}`,
	};
}

export default function Homies(props) {
	const { clientId } = props;
	const [value, setValue] = useState(2);
	const [show, setShow] = useState(false);
	const [tab, setTab] = useState();
	const [show1, setShow1] = useState(false);
	const [show2, setShow2] = useState(false);
	const [imageUrl, setImageUrl] = useState(null);
	const [currentPage, setCurrentPage] = useState(0);
	const [modalchange, setModalChange] = useState(0);

	const [selecteddtBox, setselecteddtBox] = useState(null);
	const [selecteddtBoxid, setselecteddtBoxId] = useState(null);
	const [inputValue, setInputValue] = useState("");
	const [inputValue1, setInputValue1] = useState("");

	const [itemname, setItemName] = useState("");

	const [formData, setFormData] = useState({});
	const [allitem, setAllitems] = useState("");

	const [isClicked, setIsClicked] = useState(false);
	const [mycategory, setMycategory] = useState("");

	const [loading, setLoading] = useState(false);
	const [dragimgaebox, setDragImageBox] = useState([]);
	const [selectedBoxIndex, setSelectedBoxIndex] = useState(null); // Track the clicked box
	const [newBoxes, setNewBoxes] = useState([]);

	const [storedImages, setStoredImages] = useState([]);

	const [loadingji, setLoadingji] = useState(false);

	const getOutfitData = async () => {
		setLoadingji(true);
		try {
			const response = await getoutfit(clientId);
			console.log("API Response:", response); // Log the full response

			// If the response is an object, check if it has images
			if (Array.isArray(response)) {
				setStoredImages(response);
			} else {
				console.log("Unexpected data format:", response);
				// If the response is an object, you might want to check if it has an 'images' property
				if (response.images && Array.isArray(response.images)) {
					setStoredImages([response]); // Wrap in an array if it's a single outfit object
				}
			}
		} catch (error) {
			console.error("Error fetching outfit data:", error);
		} finally {
			setLoadingji(false);
		  }
	};

	useEffect(() => {
		getOutfitData();
	}, []);
	

	// const handleCreateNewBox = () => {
	// 	const nameInput = document.querySelector(".inphdfg2");
	// 	const name = nameInput.value;
	// 	setImageUrl(null);
	// 	setIsClicked(false);
	// 	setOpencam(false);

	// 	const newBox = {
	// 		name: name || "New Box",
	// 		color: "#000000", // Set a default color or prompt the user for a color
	// 		parentIndex: selectedBoxIndex, // Store the index of the parent box
	// 		imageUrl: imageUrl,
	// 	};

	// 	// Add the new box to the newBoxes array
	// 	setNewBoxes([...newBoxes, newBox]);
	// 	setShow2(false);
	// };

	const handleCreateNewBox = async () => {
		const nameInput = document.querySelector(".inphdfg2");
		const name = nameInput.value;
		setIsClicked(false);
		setOpencam(false);

		const newBox = {
			name: name || "New Box",
			color: "#000000",
			parentIndex: selectedBoxIndex,
			imageUrlll: imageUrl,
		};

		const SubFolderData = {
			subfolder_name: newBox.name,
			// color: newBox.color,
			folder_id: newBox.parentIndex,
			image: newBox.imageUrlll,
		};
		setLoadingji(true);


		try {
			const response = await subfolder(SubFolderData);

			console.log("Subfolder created:", response);

			setNewBoxes((prevBoxes) => [...prevBoxes, newBox]);

			setShow2(false);
		} catch (error) {
			console.error("Error creating subfolder:", error.message);
		} finally {
			setLoadingji(false);
			setImageUrl(null);
		  }
	};

	//
	const [opencam, setOpencam] = useState(false);
	const handleClickCam = () => {
		setOpencam(true);
	};

	//

	const handleClick = (boxNumber) => {
		let colored = boxNumber.color;
		setselecteddtBox(colored);
		setselecteddtBoxId(boxNumber.id);
	};

	// const handleImageUpload = async () => {
	//   try {
	//     setIsClicked(false);
	//     setLoading(true);

	//     const input = document.createElement("input");
	//     input.type = "file";
	//     input.accept = "image/*";

	//     input.addEventListener("change", async (e) => {
	//       const file = e.target.files[0];
	//       if (file) {
	//         const formData = new FormData();
	//         formData.append("image_file", file);
	//         formData.append("size", "regular");

	//         const response = await fetch("https://api.remove.bg/v1.0/removebg", {
	//           method: "POST",
	//           headers: {
	//              "X-Api-Key": "sPydJdxWuNxgSxDVcHzQwdGr", // Replace 'YOUR_API_KEY' with your actual remove.bg API key
	//           },
	//           body: formData,
	//         });

	//         if (!response.ok) {
	//           throw new Error("Failed to upload image");
	//         }

	//         const imageBlob = await response.blob();
	//         const imageUrl = URL.createObjectURL(imageBlob);
	//         setImageUrl(imageUrl); // Set the imageUrl to the URL of the image with removed background
	//         setIsClicked(true); // Set isClicked to true after image upload is completed
	//       }
	//     });

	//     input.click();
	//   } catch (error) {
	//     console.error("Error handling image upload:", error);
	//   } finally {
	//     setLoading(false);
	//   }
	// }
	const handleImageUpload = () => {
		// Open the file picker dialog
		const input = document.createElement("input");
		input.type = "file";
		input.accept = "image/*";
		input.onchange = (e) => {
			const file = e.target.files[0];
			if (file) {
				const reader = new FileReader();
				reader.onloadend = () => {
					setImageUrl(reader.result);
				};
				reader.readAsDataURL(file);
			}
		};
		input.click();
	};

	const resetFormState = () => {
		setInputValue("");
		setInputValue1("");
		setMycategory("");
		setItemName("");
		setselecteddtBox(null);
		setImageUrl(null);
		setIsClicked(false);
		setOpencam(false);
	};
	const onlyCategory = (data) => {
		setMycategory(data);
	};

	const getBoxie = async () => {
		setLoadingji(true);

		try {
			await boxie(clientId).then((respons) => {
				setAllitems(respons);
				setFormData(respons);
			});
		} catch (error) {
			console.log(error);
		} finally {
			setLoadingji(false);
		  }
	};
	useEffect(() => {
		getBoxie();
	}, []);

	const handleopen = () => {
		resetFormState();
		setCurrentPage(0);
		setModalChange(0);
		setShow(true);
	};
	useEffect(() => {
		if (
			itemname &&
			inputValue &&
			inputValue1 &&
			selecteddtBox &&
			imageUrl &&
			mycategory
		) {
			// console.log("All states are updated, triggering API call");
			handleindexy();
		} else {
			console.log("States not fully updated yet");
		}
	}, [itemname, inputValue, inputValue1, selecteddtBox, imageUrl, mycategory]);

	const handleclose = () => {
		// Update all states first
		setItemName((prevItemName) => formData.sub_category || prevItemName);
		setInputValue((prevInputValue) => formData.brand_name || prevInputValue);
		setInputValue1((prevInputValue1) => formData.size || prevInputValue1);
		setselecteddtBox(
			(prevSelecteddtBox) => formData.color || prevSelecteddtBox,
		);
		setImageUrl((prevImageUrl) => formData.img || prevImageUrl);
		setMycategory((prevMycategory) => formData.category || prevMycategory);

		setShow(false);
		setIsClicked(false);
		setOpencam(false);
	};
	console.log("clientId", clientId);
	console.log("imageUrl", imageUrl);
	console.log("inputValue", inputValue);
	console.log("inputValue1", inputValue1);
	console.log("selecteddtBox", selecteddtBox);
	console.log("category", mycategory);
	console.log("itemname", itemname);

	const handleindexy = async () => {
		const Dataform = {
			client_id: clientId,
			img: imageUrl,
			brand_name: inputValue,
			size: inputValue1,
			color: selecteddtBox,
			category: mycategory,
			sub_category: itemname,
		};
		setLoadingji(true);


		try {
			const response = await indexy(Dataform);
			console.log("Responsee:", response);

			getBoxie();
		} catch (error) {
			console.error("Error posting data:", error);
		}finally {
			setLoadingji(false);
		  }
	};

	console.log("formData", formData);

	const handleopen_1 = () => {
		setShow1(true);
	};
	const handleclose_1 = () => {
		setShow1(false);
	};
	const handleopen_2 = () => {
		setShow2(true);
		setImageUrl(null);
	};
	const handleclose_2 = () => {
		setShow2(false);
		setIsClicked(false);
		setOpencam(false);
	};

	const handleChange = (key) => {
		setValue(key);
	};

	useEffect(() => {
		setValue(2);
	}, []);

	const getTabnum = (data) => {
		setTab(data);
	};

	// useEffect(() => {
	// 	const storedData = sessionStorage.getItem("formData");
	// 	if (storedData) {
	// 		setFormData(JSON.parse(storedData));
	// 	}
	// }, []);

	// Save data to sessionStorage when formData changes
	// useEffect(() => {
	//   sessionStorage.setItem('formData', JSON.stringify(formData));
	// }, [formData]);

	// Function to update formData and ensure only 5 entries are stored
	const updateFormData = (newData) => {
		setFormData((prevData) => {
			// Add new data to the beginning of the array
			const updatedData = [newData, ...prevData];
			// Ensure only 5 entries are stored
			return updatedData.slice(0, 5);
		});
	};
	// updateFormData();
	// useEffect(()=>{
	//   updateFormData();
	// },[])

	const boxData = [
		{ id: 1, color: "red" },
		{ id: 2, color: "rgb(220, 180, 220)" },
		{ id: 3, color: "rgb(238, 232, 170)" },
		{ id: 4, color: "#444913" },
		{ id: 5, color: "rgb(118, 113, 113)" },
		{ id: 6, color: "green" },
		{ id: 7, color: "white" },
	];

	const sections = [
		<div key={1}>
			<h2 className="htwoing">What brand is your item?</h2>
			<input
				value={inputValue}
				onChange={(e) => setInputValue(e.target.value)}
				placeholder="Enter brand"
				className="inplkj"
			/>
		</div>,

		<div key={2}>
			<h2 className="htwoing">What size is your item?</h2>
			<input
				value={inputValue1}
				onChange={(e) => setInputValue1(e.target.value)}
				placeholder="Enter size of your item"
				className="inplkj"
				type="number"
			/>
		</div>,

		<div key={3}>
			<h2 className="htwoing">What color is your item?</h2>
			<div className="mnbvc">(Select as many as you wish)</div>
			<div style={{ marginTop: "20px" }}></div>
			<div style={{ display: "flex" }}>
				{boxData.map((boxNumber) => (
					<div
						key={boxNumber.id}
						className={`oo${boxNumber.id}box ${
							selecteddtBoxid === boxNumber.id ? "selecteddt" : ""
						}`}
						onClick={() => handleClick(boxNumber)}
						style={{ backgroundColor: boxNumber.color }}
					></div>
				))}
			</div>
		</div>,

		<div key={4}>
			<Category
				categories={onlyCategory}
				handleclose={handleclose}
				inputValue={inputValue}
				inputValue1={inputValue1}
				selecteddtBox={selecteddtBox}
				setItemName={setItemName}
				setFormData={setFormData}
				formData={formData}
				imageUrl={imageUrl}
			/>
		</div>,
	];

	// Event handler for next button
	const handleNext = () => {
		if (currentPage < sections.length - 1) {
			setCurrentPage(currentPage + 1);
		}
	};

	// Event handler for previous button
	const handlePrevious = () => {
		if (currentPage > 0) {
			setCurrentPage(currentPage - 1);
		}
	};
	const handlemodalNext = () => {
		setModalChange(modalchange === 0 ? 1 : 0);
	};

	const handlemodalPrevious = () => {
		setModalChange(modalchange === 1 ? 0 : 1);
	};

	const renderTabIcon = () => {
		if (value !== 2) {
			return <i style={{ color: "black" }} class="bi bi-app"></i>;
		} else {
			switch (tab) {
				case "tab1":
					return (
						<div onClick={handleopen}>
							<i style={{ color: "black" }} class="bi bi-plus-lg"></i>
						</div>
					);
				case "tab2":
					return (
						<div onClick={handleopen_1}>
							<i style={{ color: "black" }} class="bi bi-plus-lg"></i>
						</div>
					);
				default:
					return (
						<div onClick={handleopen_2}>
							<i style={{ color: "black" }} class="bi bi-plus-lg"></i>
						</div>
					);
			}
		}
	};
	// console.log('Form Data:', formData)

	const handleDivClick = () => {
		setIsClicked(true);
	};
	const handleCapture = (imageSrc) => {
		setImageUrl(imageSrc); // Update the imageUrl state with the captured image URL
	};
	console.log("dragimgaebox", dragimgaebox);

	useEffect(() => {
		console.log('Loading state changed:', loadingji);
	  }, [loadingji]);

	return (
		<>
		{loadingji && (
				<div className="full-screen-loader">
				<div className="loader"></div>
				</div>
			)}
		<Row
			sx={{
				flexGrow: 1,
				bgcolor: "background.paper",
				display: "flex",
				flexDirection: "column",
				height: 224,
			}}
		>
			<TabPanel value={value} index={0}>
				<Noteic />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<ChildHomeMolecule />
			</TabPanel>
			<TabPanel value={value} index={2}>
				<ChildHome1
					getOutfitData={getOutfitData}
					setStoredImages={setStoredImages}
					storedImages={storedImages}
					getBoxie={getBoxie}
					clientId={clientId}
					setAllitems={setAllitems}
					allitem={allitem}
					imageUrl={imageUrl}
					inputValue={inputValue}
					sendTabdata={getTabnum}
					itemname={itemname}
					formData={formData}
					dragimgaebox={dragimgaebox}
					setDragImageBox={setDragImageBox}
					updateFormData={updateFormData}
					setFormData={setFormData}
					selectedBoxIndex={selectedBoxIndex}
					setSelectedBoxIndex={setSelectedBoxIndex}
					newBoxes={newBoxes}
					setNewBoxes={setNewBoxes}
				/>
			</TabPanel>
			<TabPanel value={value} index={3}>
				<Item4 />
			</TabPanel>
			<TabPanel value={value} index={4}>
				<Itemm5 />
			</TabPanel>

			<Tabs
				id="controlled-tab-example gfytf"
				activeKey={value.toString()}
				onSelect={(k) => handleChange(parseInt(k))}
				className="justify-content-center bottom-nav"
			>
				<Tab
					eventKey="0"
					title={
						<div className="nav-item-custom">
							{" "}
							<i style={{ color: "black" }} class="bi bi-journal-text"></i>
						</div>
					}
				></Tab>
				<Tab
					eventKey="1"
					title={
						<div className="nav-item-custom">
							{/* <img src={Molecule} style={{ maxWidth: '9%' }} alt="Molecule" /> */}
							<i
								style={{ color: "black" }}
								class="bi bi-gear-wide-connected"
							></i>
						</div>
					}
				></Tab>
				<Tab
					eventKey="2"
					title={<div className="nav-item-custom">{renderTabIcon()}</div>}
				></Tab>
				<Tab
					eventKey="3"
					title={
						<div className="nav-item-custom">
							<i style={{ color: "black" }} class="bi bi-handbag"></i>{" "}
						</div>
					}
				></Tab>
				<Tab
					eventKey="4"
					title={
						<div className="nav-item-custom">
							<i style={{ color: "black" }} class="bi bi-person"></i>{" "}
						</div>
					}
				></Tab>
			</Tabs>

			<Modal show={show} onHide={handleclose} centered id="idyy">
				{modalchange === 0 && (
					<>
						<Modal.Header className="dfghjkjhgfghjkjhgc"
						closeButton
						></Modal.Header>
						<Modal.Body className="bodystyleie" closeButton>
							<h2 className="helh2hq">Take a photo of your item</h2>
							<div className="cvbn">
								We recommend capturing the item only in a flat lay position,
								taken from directly above the item
							</div>
							<div className="cvbnm">
								We recommend capturing the item only in a flat lay position,
								taken from directly above the item
							</div>
							<div>
								{imageUrl ? (
									<div className="whitieboix">
										{loading ? (
											<div
												style={{
													position: "absolute",
													top: "50%",
													left: "50%",
													transform: "translate(-50%, -50%)",
													zIndex: 1,
												}}
											>
												<Spinner animation="border" role="status">
													<span className="visually-hidden">Loading...</span>
												</Spinner>
											</div>
										) : (
											<>
												<img
													src={imageUrl}
													alt="Uploaded"
													style={{
														width: "100%",
														height: "100%",
													}}
												/>
											</>
										)}
									</div>
								) : opencam ? (
									<div className="whitieboixxc">
										<Trialcam onCapture={handleCapture} />
									</div>
								) : (
									<div className="whitieboix" onClick={handleDivClick}>
										{isClicked ? (
											<div className="bgroupmar">
												<ButtonGroup aria-label>
													<Button style={{backgroundColor:"#0d6efd"}} onClick={handleClickCam}>
														Photo from Camera
													</Button>
													<Button style={{backgroundColor:"#0d6efd"}} onClick={handleImageUpload}>
														Photo from Library
													</Button>
												</ButtonGroup>
											</div>
										) : (
											<div className="alboix">CHOOSE PHOTO</div>
										)}
									</div>
								)}
							</div>
							{imageUrl && (
								<div>
									<div className="footer_bnt_collie" onClick={handlemodalNext}>
										<div className="button_name_fnext">Next</div>
										<i style={{ color: "black" }} class="bi bi-arrow-right"></i>
										{/* <ArrowRightAltIcon sx={{ color: "black" }} /> */}
									</div>
								</div>
							)}
						</Modal.Body>
					</>
				)}
				{modalchange === 1 && (
					<>
						<Modal.Header className="dfghjkjhgfghjkjhgc" style={{ justifyContent: "space-between" }}>
							<div
								style={{ backgroundColor: "transparent", cursor:"pointer" }}
								onClick={
									currentPage === 0 ? handlemodalPrevious : handlePrevious
								}
							>
								<i style={{ color: "black" }} class="bi bi-arrow-left"></i>
							</div>
							<h1 className="helh2hqQ">CREATE ITEM</h1>
							<div> . </div>
						</Modal.Header>
						<Modal.Body style={{ overflowY: "hidden", backgroundColor:"rgb(244, 244, 232)", boxShadow:"none", borderBottomRightRadius:"40px" }}>
							<div className="boiiboix">
								<div
									style={{
										float: "right",
										marginRight: "10px",
										marginTop: "10px",
									}}
								>
									{/* <CancelOutlinedIcon onClick={handleclose} /> */}
								</div>
								{sections[currentPage]}
								<div>
									{/* Display current section */}
									<CustomPagination
										onNext={handleNext}
										onPrevious={handlePrevious}
										currentPage={currentPage}
									/>
								</div>
							</div>
						</Modal.Body>
					</>
				)}
			</Modal>

			{/* /////// */}

			<Modal show={show1} onHide={handleclose_1} centered id="askjdhsjhdkj">
				<CreateOutfit
					clientId={clientId}
					getOutfitData={getOutfitData}
					allitem={allitem}
					formData={formData}
					handleclose_1={handleclose_1}
					setDragImageBox={setDragImageBox}
				/>
			</Modal>

			{/* ///// */}

			<Modal show={show2} onHide={handleclose_2} centered id="idyqwey">
				<Modal.Body className="bodystyle" closeButton>
					<Modal.Header className="modal_headerer" style={{ margin: "0", padding: "0" }} closeButton>
						<div
							style={{
								width: "100%",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<h2 className="llpllp">CREATE COLLECTION</h2>
						</div>
					</Modal.Header>

					<h2 className="helh2asa">NAME*</h2>
					<input className="inphdfg2" placeholder="Enter Name" />

					<h2 className="cimg">COVER IMAGE</h2>

					<div>
						{imageUrl ? (
							<div className="hitieboixie">
								{loading ? (
									<div
										style={{
											position: "absolute",
											top: "50%",
											left: "50%",
											transform: "translate(-50%, -50%)",
											zIndex: 1,
										}}
									>
										{/* <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner> */}
									</div>
								) : (
									<>
										<img
											src={imageUrl}
											alt="Uploaded"
											style={{
												width: "100%",
												height: "100%",
											}}
										/>
									</>
								)}
							</div>
						) : opencam ? (
							<div className="whitieboixxcyion">
								<Trialcam onCapture={handleCapture} />
							</div>
						) : (
							<div className="whitieboix" onClick={handleDivClick}>
								{isClicked ? (
									<div className="bgroupmar">
										<ButtonGroup
											orientation="vertical"
											aria-label="Vertical button group"
											variant="contained"
										>
											<Button style={{backgroundColor:"#0d6efd"}} onClick={handleClickCam}>
												Photo from Camera
											</Button>
											<Button style={{backgroundColor:"#0d6efd"}} onClick={handleImageUpload}>
												Photo from Library
											</Button>
										</ButtonGroup>
									</div>
								) : (
									<div className="alboix">CHOOSE PHOTO</div>
								)}
							</div>
						)}
					</div>

					<div className="line121"></div>
					<div>
						<div className="footer_bnt_coll" onClick={handleCreateNewBox}>
							<div className="button_name_f">CREATE</div>
							<i style={{ color: "white" }} class="bi bi-arrow-right"></i>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</Row>
		</>
	);
}
