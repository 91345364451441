import React, { useState } from 'react';
import './ChildHomeMolecule.css'; // Import your CSS file for styling
import CHMfeatured from './CHMfeatured';



function ChildHomeMolecule() {
  const [activeTab, setActiveTab] = useState('tab1');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className='container'>
    <div className="tab-containerrr" > 
      <div className="tab-boxxx">
        <button
          className={activeTab === 'tab1' ? 'tab-btnnn active' : 'tab-btnnn'}
          onClick={() => handleTabClick('tab1')}
        >
          FEATURD
        </button>
        <button
          className={activeTab === 'tab2' ? 'tab-btnnn active' : 'tab-btnnn'}
          onClick={() => handleTabClick('tab2')}
        >
          PINNED
        </button>
      
      </div>
      <div className="tab-contenttt" style={{ display: activeTab === 'tab1' ? 'block' : 'none' }}>
        <CHMfeatured/>
      </div>
      <div className="tab-content" style={{ display: activeTab === 'tab2' ? 'block' : 'none' }}>
        <h2> </h2>
        {/* Your content for Tab 2 goes here */}
      </div>
   
    </div>
    </div>
  );
}



export default ChildHomeMolecule;
